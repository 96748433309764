document.addEventListener('DOMContentLoaded', function () {

    document.getElementById('afms_logistics').addEventListener('mouseover', () => {

        let stop_color_last = document.querySelector('.background-logo-animation svg stop:last-child').getAttribute("stop-color")
        let stop_color_first = document.querySelector('.background-logo-animation svg stop:first-child').getAttribute("stop-color")

        if(stop_color_last != "#358ff44d"){
            document.querySelector('.background-logo-animation svg stop:last-child').style.stopColor = "#358ff44d";
            document.querySelector('.background-logo-animation svg stop:last-child').setAttribute("stop-color", "#358ff44d")

            document.querySelector('.background-circle-animation svg stop:last-child').style.stopColor = "#358ff44d";
            document.querySelector('.background-circle-animation svg stop:last-child').setAttribute("stop-color", "#358ff44d")
        }

        if(stop_color_first != "#358ff403"){
            document.querySelector('.background-logo-animation svg stop:first-child').style.stopColor = "#358ff403";
            document.querySelector('.background-logo-animation svg stop:first-child').setAttribute("stop-color", "#358ff403")

            document.querySelector('.background-circle-animation svg stop:first-child').style.stopColor = "#358ff403";
            document.querySelector('.background-circle-animation svg stop:first-child').setAttribute("stop-color", "#358ff403")
        }
    })

    document.getElementById('afms_stations').addEventListener('mouseover', () => {
        let stop_color_last = document.querySelector('.background-logo-animation svg stop:last-child').getAttribute("stop-color")
        let stop_color_first = document.querySelector('.background-logo-animation svg stop:first-child').getAttribute("stop-color")

        if(stop_color_last != "#ff47471a"){
            document.querySelector('.background-logo-animation svg stop:last-child').style.stopColor = "#ff47471a";
            document.querySelector('.background-logo-animation svg stop:last-child').setAttribute("stop-color", "#ff47471a")

            document.querySelector('.background-circle-animation svg stop:last-child').style.stopColor = "#ff47471a";
            document.querySelector('.background-circle-animation svg stop:last-child').setAttribute("stop-color", "#ff47471a")
        }

        if(stop_color_first != "#ff474703"){
            document.querySelector('.background-logo-animation svg stop:first-child').style.stopColor = "#ff474703";
            document.querySelector('.background-logo-animation svg stop:first-child').setAttribute("stop-color", "#ff474703")

            document.querySelector('.background-circle-animation svg stop:first-child').style.stopColor = "#ff474703";
            document.querySelector('.background-circle-animation svg stop:first-child').setAttribute("stop-color", "#ff474703")
        }
    })

}, false);